import { FC, Fragment } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

type ControlEditWithIconProps = {
    label: string;
    text: string;
    helpMessage?: string;
    Icon?: React.ElementType;
    errorMessage?: string;
    className?: string;
    minValue: number;
    maxValue: number;
    value: number;
    onIncrement: (value: number) => void;
    onDecrement: (value: number) => void;
    disabled?: boolean;
}

const ControlEditWithIcon: FC<ControlEditWithIconProps> = ({
    label,
    text,
    helpMessage,
    Icon,
    errorMessage,
    minValue,
    maxValue,
    value,
    onIncrement,
    onDecrement,
    disabled = false
}) => {
    const error = errorMessage ? true : false;
    const borderColor = error ? "border-red-600" : "border-gray-300";

    const increment = () => {
        const newValue = value + 1;
        if (newValue <= maxValue) {
            onIncrement(newValue);
        }
    };

    const decrement = () => {
        const newValue = value - 1;
        if (newValue >= minValue) {
            onDecrement(newValue);
        }
    };

    return (
        <Fragment>
            <label htmlFor="control-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {label}
            </label>
            <div className="relative flex items-center max-w-[11rem]">
                <button
                    type="button"
                    id="decrement-button"
                    onClick={decrement}
                    className={`bg-gray-100 hover:bg-gray-200 border ${borderColor} rounded-s-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed`}
                    disabled={value <= minValue || disabled}
                >
                    <FaMinus />
                </button>

                <span
                    id="control-input"
                    className={`bg-gray-50 border-x-0 border ${borderColor} h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6`}
                >
                    {value}
                </span>

                <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
                    {Icon && <Icon className="w-5 h-5" />}
                    <span>{text}</span>
                </div>

                <button
                    type="button"
                    id="increment-button"
                    onClick={increment}
                    className={`bg-gray-100 hover:bg-gray-200 border ${borderColor} rounded-e-lg p-3 h-11 focus:ring-gray-100 focus:ring-2 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed`}
                    disabled={value >= maxValue || disabled}
                >
                    <FaPlus />
                </button>
            </div>
            {error ? (
                <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
            ) : (
                <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {helpMessage}
                </p>
            )}
        </Fragment>
    );
};

export default ControlEditWithIcon;